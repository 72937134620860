import React from 'react'
import styled from "@emotion/styled"

const Button = styled.button`
  font-weight: bold; 
  text-transform: uppercase; 
  fill: #2b2b2b; 
  color: #2b2b2b; 
  background-color: #fcaf3b; 
  border-radius: 0px 0px 0px 0px; 
  font-size: 16px; 
  padding: 15px 30px;
`

export default () =>
  (<Button>Lets's talk</Button>
  )

