/** @jsx jsx */
import {jsx} from "theme-ui"
import {Link} from "gatsby"
import Layout from "@lekoarts/gatsby-theme-minimal-blog/src/components/layout"
// @ts-ignore
import Hero from "@lekoarts/gatsby-theme-minimal-blog/src/texts/hero"
// @ts-ignore
import Bottom from "@lekoarts/gatsby-theme-minimal-blog/src/texts/bottom"
import Title from "@lekoarts/gatsby-theme-minimal-blog/src/components/title"
import Listing from "@lekoarts/gatsby-theme-minimal-blog/src/components/listing"
import List from "@lekoarts/gatsby-theme-minimal-blog/src/components/list"
import useMinimalBlogConfig from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-minimal-blog-config"
import replaceSlashes from "@lekoarts/gatsby-theme-minimal-blog/src/utils/replaceSlashes"




const Homepage = ({posts}) => {
  const {basePath, blogPath} = useMinimalBlogConfig()

  return (
    <Layout>
      <section sx={{mb: [5, 6, 5], p: {fontSize: [1, 2, 3], mt: 2}}}>
        <Hero/>
      </section>

      <Title text="Latest Posts">
        <Link to={replaceSlashes(`/${basePath}/${blogPath}`)}>Read all posts</Link>
      </Title>

      <Listing posts={posts} showTags={false}/>
      <List>
        <Bottom/>
      </List>
    </Layout>
  )
}

export default Homepage
