import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Button from '../components/button';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
Cloud Solution Engineering
    </Text>
    <p>{`Hi, I'm Peter - AWS Solutions Architect, Machine Learning Engineer, CTO, DevOps Consultant, Software Engineer.`}</p>
    <p>{`I have over 10 years of experience in software engineering and management positions both in startups and in large corporations and can
switch seamlessly between business strategy and technological roles. I like to apply my strong machine learning
background to solve problems but I merely see ML as one of many tools in my toolbox.
My main technical expertise is AWS Solution Architecture, infrastructure-as-code, data intensive machine learning stacks, JVM and Python engineering.`}</p>
    <p>{`I am available for freelance projects. `}<a href="/contact">{`Hire me.`}</a></p>
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
Why work with me?
    </Text>
    <p>{`Over the years I have seen businesses from many perspectives.`}</p>
    <p>{`As a CTO my team and I built up from scratch the technology for Europe’s fastest growing online art platform `}<a parentName="p" {...{
        "href": "https://www.juniqe.com/"
      }}>{`JUNIQE.COM`}</a>{`.`}</p>
    <p>{`As a Machine Learning Consultant for `}<a parentName="p" {...{
        "href": "https://en.zalando.de/"
      }}>{`Zalando`}</a>{` I developed a state of the art Fashion Recommendation Engine.
For Zanox (now `}<a parentName="p" {...{
        "href": "https://www.awin.com/"
      }}>{`Awin`}</a>{`) I designed and coded a Fraud Detection System for their advertising business and many more startups in the Berlin area I helped with general Machine Learning consulting and solution engineering.`}</p>
    <p>{`As a full stack Engineer I built as a one-man-show a real estate comparison and rating website for the Australian market.`}</p>
    <p>{`As a Machine Learning Software Engineer I developed Place Recommendations and Similar Places Search Solutions for Nokia Maps.`}</p>
    <p>{`As an independent Cloud Solution Engineer I solve problems with technology – by designing and rolling out large enterprise infrastructure-as-code stacks,
writing machine learning enabled/data driven applications and websites or by just by scripting a necessary automation. `}<a style={{
        "fontSize": "bold"
      }} href="/contact">{`Let's talk!`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      